<template>
  <router-view />
  <PreviewImages></PreviewImages>
</template>

<script lang="ts">
  import PreviewImages from '@/components/previewImages/index.vue';

  export default {
    name: 'App',
    components: {
      PreviewImages,
    },
  };
</script>
