<template>
  <div>
    <el-image-viewer
      v-if="store.previewPictureModalFlag"
      :url-list="store.previewPictureModalImgSrc"
      :z-index="3000"
      :infinite="false"
      @close="close"
    ></el-image-viewer>
  </div>
</template>

<script lang="ts" setup>
  import { mainStore } from '@/store';

  const store = mainStore();

  const close = () => {
    store.previewPictureModalClick(false);
  };
</script>

<style lang="scss" scoped></style>
