const runType = import.meta.env.APP_FLAG;
let apiPrefixPath = '';
let SIGNKEY = '';
export let ossPath = '';
switch (runType) {
    case 'pro': // 线上环境
        apiPrefixPath = 'https://cms.swiftfintech.com/cms';
        ossPath = 'https://oss.swiftfintech.com';
        SIGNKEY = 'EDH6sOgEILMSMPkJt78ilfMUpJ5FajXJ';
        break;
    case 'test': // 外网测试环境
        apiPrefixPath = 'https://test-cms.swiftfintech.com/cms';
        ossPath = 'https://oss.swiftfintech.com';
        SIGNKEY = 'EDH6sOgEILMSMPkJt78ilfMUpJ5FajXJ';
        break;
    default: // 默认环境  test-admin.swiftfintech.com   test-cms.tflixing.cn  https://test-cms.swiftfintech.com/cms http://121.41.171.181:9992/cms
        apiPrefixPath = 'https://test-cms.swiftfintech.com/cms';
        ossPath = 'https://oss.swiftfintech.com';
        SIGNKEY = 'EDH6sOgEILMSMPkJt78ilfMUpJ5FajXJ';
        break;
}
export default {
    apiPrefixPath,
    SIGNKEY,
    ossPath,
};
