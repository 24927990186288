import service, { AxiosRequestConfig } from './axios';

export const request = <T = any>(config: AxiosRequestConfig): Promise<T> => {
  return new Promise((resolve, reject) => {
    service
      .request(config)
      .then((res) => {
        const data =
          config.responseType == 'blob' || config?.headers?.backRes ? res : res && res.data;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const http = {
  get<T = any>(
    url: string,
    params?: object | null | undefined,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return request({ url, params, ...config, method: 'GET' });
  },
  getId<T = any>(
    url: string,
    params?: object | null | undefined,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return request({ url, params, ...config, method: 'GET' });
  },
  /*
   * 传给后台是application/json 加密方式
   * */
  post<T = any>(
    url: string,
    data?: object | null | undefined,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return request({ url, data, ...config, method: 'POST' });
  },
  /*
   * 传给后台是formData的数据结果
   *  */
  postForm<T = any>(
    url: string,
    data?: object | null | undefined,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return request({ url, data, ...config, method: 'POST_FORM' });
  },
};
type Http = keyof typeof http;
export type { Http };
export default http;
