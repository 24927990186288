interface page {
  [key: string]: any;
}

export default {
  Main: () => import('@/components/main/main.vue'),
  home: () => import('@/views/home/home.vue'),

  /* 用户管理 */
  userList: () => import('@/views/userManagement/userList/userList.vue'), // 用户查询
  userDistribution: () => import('@/views/userManagement/userDistribution/index.vue'), // 用户分布

  /* 投放管理 */
  channelList: () => import('@/views/channelManagement/channelList/channelList.vue'), // 渠道列表
  pageList: () => import('@/views/channelManagement/pageList/pageList.vue'), // 渠道投放页
  channelGroup: () => import('@/views/channelManagement/channelGroup/channelGroup.vue'), // 渠道分组
  channelStatistic: () => import('@/views/channelManagement/channelStatistic/channelStatistic.vue'), // 渠道数据统计
  channelRevenueStatistics: () =>
    import('@/views/channelManagement/channelRevenueStatistics/channelRevenueStatistics.vue'), //渠道收益统计
  registerPageStatistics: () =>
    import('@/views/channelManagement/registerPageStatistics/registerPageStatistics.vue'), // 注册页数据统计
  appLeaveInfoStatistic: () =>
    import('@/views/channelManagement/appLeaveInfoStatistic/appLeaveInfoStatistic.vue'), // app留资统计
  marketingManagement: () => import('@/views/channelManagement/marketingManagement/index.vue'), //应用市场管理

  /* 贷超管理 */
  productList: () => import('@/views/productManagement/productList/productList.vue'), // 产品列表
  productIncome: () => import('@/views/productManagement/productIncome/productIncome.vue'), // 产品收益
  channelProductConfig: () =>
    import('@/views/productManagement/channelProductConfig/channelProductConfig.vue'), // 渠道产品配置

  /* 数据统计 */
  dataOverview: () => import('@/views/dataManagement/dataOverview/dataOverview.vue'), // 数据概览

  /* 运营管理 */
  operateData: () => import('@/views/operateManagement/operateData/operateData.vue'), // 运营数据
  operatePositionManege: () =>
    import('@/views/operateManagement/operatePositionManege/operatePositionManege.vue'), // 运营位管理
  popUpStatistic: () => import('@/views/operateManagement/popUpStatistic/popUpStatistic.vue'), // 弹窗数据统计
  dataMarket: () => import('@/views/operateManagement/dataMarket/index.vue'), // 大盘数据
  feedback: () => import('@/views/operateManagement/feedback/index.vue'), // 反馈管理
  userBehaviorData: () => import('@/views/operateManagement/userBehaviorData/index.vue'), // 反馈管理

  /* 系统管理 */
  adminUser: () => import('@/views/systemManagement/user/user.vue'), // 用户管理
  adminRole: () => import('@/views/systemManagement/role/role.vue'), // 角色管理
  sysModule: () => import('@/views/systemManagement/sysModule/sysModule.vue'), // 菜单管理
} as page;
