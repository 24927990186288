<template>
  <div class="login">
    <div class="login-con">
      <img src="@/assets/images/login-icon.png" alt="" />
      <p class="title">天下分期</p>
      <div class="form-con">
        <login-form @on-success-valid="handleSubmit"></login-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import LoginForm from '_c/login-form';
  import adminConfig from '@/libs/config.js';
  import basics from '@/libs/basics';
  import http from '@/libs/service/http.js';
  import { Auth } from '@/libs/requestAddress';

  export default {
    components: {
      LoginForm,
    },
    setup() {
      const router = useRouter();
      const handleSubmit = (user: { phone: string; password: string }) => {
        http.post(Auth.loginPass, user).then((res) => {
          basics.Storage.setSession(adminConfig.store.authorCacheName, res);
          sessionStorage.setItem('userPhone', user.phone)
          router.replace({
            path: '/',
          });
        });
      };

      return {
        handleSubmit,
      };
    },
  };
</script>
<style lang="less">
  @import './login.less';
</style>
