<template>
  <el-form
    ref="loginForm"
    :model="state.form"
    :rules="state.rules"
    @keydown.enter="submitForm(loginForm)"
  >
    <el-form-item prop="userName">
      <el-input
        v-model="state.form.userName"
        placeholder="请输入手机号"
        :maxlength="11"
        class="inputStyle"
        :formatter="(value: string) => `${value}`.replace(/[^0-9-]+/, '')"
        :parser="(value: string) => value.replace(/[^0-9-]+/, '')"
      >
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="state.form.password"
        placeholder="请输入密码"
        type="password"
        show-password
        class="inputStyle"
      >
      </el-input>
    </el-form-item>
    <!--    <el-form-item prop="password">-->
    <!--      <el-input-->
    <!--        v-model="state.form.password"-->
    <!--        placeholder="请输入验证码"-->
    <!--        :maxlength="4"-->
    <!--        :formatter="(value: string) => `${value}`.replace(/[^0-9-]+/, '')"-->
    <!--        :parser="(value: string) => value.replace(/[^0-9-]+/, '')"-->
    <!--      >-->
    <!--        <template #prepend>-->
    <!--          <el-icon :size="16">-->
    <!--            <Lock />-->
    <!--          </el-icon>-->
    <!--        </template>-->
    <!--        <template #append>-->
    <!--          <p style="cursor: pointer" v-show="state.show" @click="getSms()">获取验证码</p>-->
    <!--          <p style="cursor: pointer" v-show="!state.show">{{ state.counts }}S后重新获取</p>-->
    <!--        </template>-->
    <!--      </el-input>-->
    <!--    </el-form-item>-->
    <el-form-item>
      <el-button @click="submitForm(loginForm)" type="primary" class="btn">登录 </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts" setup>
  import { ElMessage } from 'element-plus';
  import basics from '@/libs/basics';
  import http from '@/libs/service/http.js';
  import type { FormInstance } from 'element-plus';
  import { Auth } from '@/libs/requestAddress';

  const emit = defineEmits(['on-success-valid']);
  const validatePhone = (rule: any, value: string, callback: any) => {
    if (!value) {
      return callback(new Error('手机号不能为空'));
    } else if (!/^1[3456789]\d{9}$/.test(value)) {
      callback('手机号格式不正确');
    } else {
      callback();
    }
  };
  let timer: number | null = null;
  const state = reactive({
    show: true,
    counts: 60,
    form: {
      userName: '',
      password: '',
    },
    rules: {
      userName: [
        {
          validator: validatePhone,
        },
      ],
      password: [
        {
          message: '验证不能为空',
          required: true,
        },
      ],
    },
  });

  const loginForm = ref<FormInstance>();

  const submitForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        let user = {
          phone: state.form.userName,
          password: state.form.password, // smsCode
        };
        emit('on-success-valid', user);
      }
    });
  };
  const getSms = basics.debounce(function () {
    if (/^1[3456789]\d{9}$/.test(state.form.userName)) {
      let jsonPhone = { phone: state.form.userName };
      http.get(Auth.sendSms, jsonPhone).then(() => {
        getCode();
      });
    } else {
      ElMessage.error('手机号码错误');
    }
  }, 500);

  const getCode = () => {
    const TIME_COUNT = 60;
    if (!timer) {
      state.counts = TIME_COUNT;
      state.show = false;
      timer = setInterval(() => {
        if (state.counts > 0 && state.counts <= TIME_COUNT) {
          state.counts--;
        } else {
          state.show = true;
          clearInterval(timer as number);
          timer = null;
        }
      }, 1000);
    }
  };
</script>

<style lang="less" scoped>
  .inputStyle {
    width: 370px;
    height: 55px;
    background: #f4f6ff;
    box-shadow: inset 0px 0px 7px 0px rgba(67, 97, 255, 0.08);
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    font-size: 18px;
    margin-top: 20px;
    :deep(.el-input__wrapper) {
      background-color: transparent;
    }
  }

  .btn {
    width: 370px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    background: #4361ff;
    border-radius: 5px 5px 5px 5px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 20px;
  }
</style>
