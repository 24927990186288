import adminConfig from './config.js';
import { ElMessage } from 'element-plus';

const basics = {
  isNull(o: any): boolean {
    return o === null || o === '' || o === undefined;
  },
  isObj(o: any): boolean {
    // 是否对象
    return Object.prototype.toString.call(o).slice(8, -1) === 'Object';
  },
  isArray(o: any): boolean {
    // 是否数组
    return Object.prototype.toString.call(o) === '[object Array]';
  },
  Storage: {
    /* 设置localStorage */
    setLocal(key: string, val: any): void {
      // eslint-disable-next-line prefer-rest-params
      const setting = arguments[0];
      if (Object.prototype.toString.call(setting).slice(8, -1) === 'Object') {
        for (const i in setting) {
          localStorage.setItem(i, JSON.stringify(setting[i]));
        }
      } else {
        localStorage.setItem(key, JSON.stringify(val));
      }
    },
    /* 获取localStorage */
    getLocal(key: string) {
      if (key) {
        return JSON.parse(localStorage.getItem(key) as string);
      }
      return null;
    },
    /* 设置sessionStorage */
    setSession(key: string, val: any) {
      // eslint-disable-next-line prefer-rest-params
      const setting = arguments[0];
      if (Object.prototype.toString.call(setting).slice(8, -1) === 'Object') {
        for (const i in setting) {
          sessionStorage.setItem(i, JSON.stringify(setting[i]));
        }
      } else {
        sessionStorage.setItem(key, JSON.stringify(val));
      }
    },
    /* 获取sessionStorage */
    getSession(key: string) {
      if (key) return JSON.parse(sessionStorage.getItem(key) as string);
      return null;
    },
    cacheReadSession() {
      // 获取当前用户缓存
      return this.getSession(adminConfig.store.authorCacheName);
    },
    isLogin(): boolean {
      // 判断是否登录
      return this.cacheReadSession();
    },
  },
  Toast: {
    // 提示框
    init(state: any, msg: string): void {
      ElMessage({
        message: msg,
        type: state,
      });
    },
  },

  // 防抖
  // eslint-disable-next-line @typescript-eslint/ban-types
  debounce(fn: Function, delay: number) {
    let timeout: number | null = null;
    return function () {
      // eslint-disable-next-line prefer-rest-params
      const arg = arguments;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      clearTimeout(timeout as number);
      !timeout && fn.apply(that, arg);
      timeout = setTimeout(function () {
        timeout = null;
      }, delay);
    };
  },
};
export default basics;
